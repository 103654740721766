@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html,
body {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
